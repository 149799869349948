// FirebaseContext.tsx

import { initializeApp } from "firebase/app";
import "firebase/auth"; // Import the Firebase authentication module
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Initialize Firebase with your configuration values
const firebaseConfig = {
  apiKey: "AIzaSyD-LYB-k57H4GWeb46IsBH4jXzWbYrsg78",
  authDomain: "chatgpt-extension-4d7a4.firebaseapp.com",
  projectId: "chatgpt-extension-4d7a4",
  storageBucket: "chatgpt-extension-4d7a4.appspot.com",
  messagingSenderId: "893542471063",
  appId: "1:893542471063:web:a936a2ee1a6959649720d2",
  measurementId: "G-FRXSDLW54L",
};

// Initialize the Firebase app
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const database = getFirestore(app);
export const provider = new GoogleAuthProvider();

// Server functions
const functions = getFunctions();
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);
export const getAuthToken = httpsCallable(functions, "getAuthToken");
//const prodFunctions = getFunctions();
export const createPortalLink = httpsCallable<
  { returnUrl: string },
  {
    url: string;
  }
>(functions, "ext-firestore-stripe-payments-createPortalLink");
