import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  useColorModeValue,
  Text,
  Container,
  Heading,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

export default function SimpleAccordion() {
  return (
    <Flex
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      w={"100dvw"}
      flexDirection={"column"}
      color={"primary.800"}
    >
      <Heading my="10" textAlign={"center"}>
        FAQ
      </Heading>
      <Container>
        <Accordion allowMultiple width="100%" maxW="lg" rounded="lg">
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">Is ReadPal.ai free?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                Yes, you get 20 queries to try it out for free. We also have
                paid plans that give you more quota and support for more types
                of content.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">How to start using ReadPal.ai?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                It's super simple to get started! Install the extension in your
                chrome and pin it for easy access. Sign up and chat away!
                <br />
                Some quick tips:
                <br />
                <ul>
                  <li>
                    You can use Ctrl(Cmd) + Shift + P to easily turn on/off
                  </li>
                  <li>
                    You can right click on any parts of the web page or selected
                    text to trigger predefined commands.
                  </li>
                </ul>
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">What AI model does ReadPal.ai use?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                We use gpt-3.5-turbo-1106 for now and are not committed to any
                single model. We might mix up different models for different
                tasks if they're a better fit and might train our own models in
                the future.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">How are you handling my data?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                We take your privacy very seriously. We do NOT store any of your
                data beyond sign in information and usage statistics on our end!
                Your queries to our third party AI model providers are
                anonymized and your chat history is stored locally in your own
                browser.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">What web pages can I use ReadPal.ai on?</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                You can use ReadPal.ai on a wide range of web pages, including
                Google Docs, PDFs online. Support for YouTube videos is also
                coming soon. <br />A quick tip: On websites where content loads
                dynamically, like Reddit, it helps the AI understand better if
                you view the page on its own. For example, in Reddit, refresh a
                post to see it on a full page, instead of within the subreddit.
                Make sure all the content you want the AI to interact with is
                fully loaded before you start asking questions.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">
                Do you support any other browsers besides Chrome?
              </Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                Unfortunately we only support Chrome at this time. However,
                we're actively looking into extending to other platforms. If you
                have a preference on which one we should focus on next, let us
                know!
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem mb="20">
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Text fontSize="md">Billing and refunds</Text>
              <ChevronDownIcon fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                As we pay high cost to our AI model providers, so we can not
                provide any form of refunds, whether partial or in full, at this
                time. However, you can cancel your subscription through the
                billing portal whenever you want to. Once you cancel, no further
                charges will be incurred.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Flex>
  );
}
