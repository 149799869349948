import React from "react";
import { User } from "firebase/auth";

export const AuthContext = React.createContext<{
  user: User | null;
  manuallyLoggedOut: boolean;
  setManuallyLoggedOut: (state: boolean) => void;
}>({
  user: null,
  manuallyLoggedOut: false,
  setManuallyLoggedOut: (state: boolean) => {},
});
