import React, { useState } from "react";
import { auth, provider } from "../services/Firebase";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Divider,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  VStack,
  AbsoluteCenter,
  Text,
} from "@chakra-ui/react";
import { sendSignInLinkToEmail, signInWithPopup } from "firebase/auth";
import { AuthContext } from "../context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { logData } from "../utils/helpers";

const Login = () => {
  const authData = React.useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [agreement, setAgreement] = useState(false);
  const [email, setEmail] = useState("");

  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const emailSignIn = () => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: searchParams.get("redirectTo")
        ? `https://readpal.ai${searchParams.get("redirectTo")}`
        : "https://readpal.ai",
      // This must be true.
      handleCodeInApp: true,
    };
    setSendingEmail(true);
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        setEmailSent(true);
        setSendingEmail(false);
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        setSendingEmail(false);
        const errorMessage = error.message;
        setErrorMsg(errorMessage);
        logData({
          text: `Send Email link failed with:${errorMessage}`,
          severity: "error",
        });
      });
  };

  if (emailSent) {
    return (
      <Center height={"100dvh"}>
        <Card size="lg" border={"1px"} borderColor={"primary.500"}>
          <CardBody m="28">
            <Image boxSize="50px" src="/icon128.png" />
            <Text>
              Check your inbox for a magic link! Clicking it will sign you in to
              both the website and the extension. Enjoy your experience!
            </Text>
          </CardBody>
        </Card>
      </Center>
    );
  }

  return (
    <Center height={"100dvh"}>
      <Card size="lg" border={"1px"} borderColor={"primary.500"}>
        <CardBody m="28">
          <VStack spacing={10}>
            <HStack>
              <Image boxSize="30px" src="/icon128.png" />
              <Heading color="green.600" size={"lg"}>
                ReadPal.ai
              </Heading>
            </HStack>
            <Button
              colorScheme="primary"
              size="md"
              width={"80%"}
              onClick={() => {
                signInWithPopup(auth, provider).then(() => {
                  authData.setManuallyLoggedOut(false);
                  navigate(searchParams.get("redirectTo") ?? "/");
                });
              }}
              variant={"outline"}
            >
              <Icon as={FcGoogle} />
              &nbsp;
              {"Sign in with Google"}
            </Button>
            <Box position={"relative"} padding="5" width={"100%"}>
              <Divider />
              <AbsoluteCenter bg="white" px="4">
                OR
              </AbsoluteCenter>
            </Box>
            <VStack spacing={6}>
              <Input
                placeholder="Your email"
                w="80%"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                colorScheme="primary"
                size="md"
                width={"80%"}
                onClick={() => {
                  emailSignIn();
                }}
                isLoading={sendingEmail}
              >
                Sign in with Email
              </Button>
              <Box w="80%" fontSize={"xs"} color={"green.600"}>
                {errorMsg
                  ? errorMsg
                  : "We'll email you a magic link for a password-free sign in."}
              </Box>
            </VStack>
          </VStack>
        </CardBody>
      </Card>
    </Center>
  );
};

export default Login;
