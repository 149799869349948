import { Avatar, Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";

export default function WithLargeQuote() {
  return (
    <Stack
      bg={useColorModeValue("gray.50", "gray.800")}
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={"center"}
      direction={"column"}
      w={"100dvw"}
    >
      <Text
        fontSize={{ base: "xl", md: "2xl" }}
        textAlign={"center"}
        maxW={"3xl"}
        color={"primary.800"}
      >
        It has transformed my browsing experience! I'm much more focused and
        productive now.
      </Text>
      <Box textAlign={"center"}>
        <Avatar src={"/testimonial-avatar.png"} mb={2} />

        <Text fontWeight={600} color={"primary.800"}>
          Jennie Wilson
        </Text>
      </Box>
    </Stack>
  );
}
