import React from "react";
import StripePricingTable from "../components/StripePricingTable";
import { AuthContext } from "../context/AuthContext";

export default function Pricing({ ...props }) {
  const authData = React.useContext(AuthContext);
  return (
    <div className="app">
      <StripePricingTable user={authData.user} />
    </div>
  );
}
