import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Image } from "@chakra-ui/react";

export const Footer = ({ ...props }) => (
  <Container
    as="footer"
    role="contentinfo"
    py={{ base: "12", md: "16" }}
    maxW="100%"
    {...props}
  >
    <Stack spacing={{ base: "4", md: "3" }}>
      <Stack direction="row" align="center">
        <Flex align="center">
          <Image boxSize="30px" src="/icon128.png" />
          &nbsp;
          <Heading size={"xl"} color="green.600">
            ReadPal.ai
          </Heading>
        </Flex>
        {/* <ButtonGroup variant="tertiary">
          <IconButton
            as="a"
            href="#"
            aria-label="LinkedIn"
            icon={<FaLinkedin />}
          />
          <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub />} />
          <IconButton
            as="a"
            href="#"
            aria-label="Twitter"
            icon={<FaTwitter />}
          />
        </ButtonGroup> */}
      </Stack>
      <Stack direction="row" align="center">
        <Button
          variant={"ghost"}
          size="sm"
          as="a"
          href="/privacy-policy"
          color={"green.800"}
          px={"0.5"}
        >
          Privacy Policy
        </Button>
      </Stack>
      <Text fontSize="sm" color="green.800">
        &copy; {new Date().getFullYear()} ReadPal, Inc. All rights reserved.
      </Text>
    </Stack>
  </Container>
);
