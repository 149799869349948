import React, { ReactNode, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Flex, Text, Button, Heading } from "@chakra-ui/react";
import { AuthContext } from "../context/AuthContext";
import { auth, createPortalLink, database } from "../services/Firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Image } from "@chakra-ui/react";

const MenuItem = (props: {
  children: ReactNode;
  isLast?: boolean;
  to?: string;
  onClick?: () => void;
}) => {
  const { children, isLast, to, onClick } = props;
  return (
    <Text
      mb={{ base: isLast ? 0 : 8, sm: 0 }}
      mr={{ base: 0, sm: isLast ? 0 : 8 }}
      display="block"
      {...props}
    >
      {onClick && (
        <Link onClick={onClick} to={""}>
          {children}
        </Link>
      )}
      {to && <Link to={to}>{children}</Link>}
    </Text>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Header = ({ ...props }) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);
  const authData = React.useContext(AuthContext);
  const [isValidSubscription, setIsValidSubscription] = React.useState(false);
  const [billingClicked, setBillingClicked] = React.useState(false);
  const location = useLocation();

  const scrollToElement = (elementId: string) => {
    const faqElement = document.getElementById(elementId);
    faqElement?.scrollIntoView();
  };

  useEffect(() => {
    if (location.hash) {
      var elementId = location.hash.substring(1); // Remove the '#'
      scrollToElement(elementId);
    }
  }, [location.hash]);

  useEffect(() => {
    if (!authData.user) {
      setIsValidSubscription(false);
      return;
    }
    const subscriptionRef = collection(
      database,
      "users",
      authData.user?.uid,
      "subscriptions"
    );
    const q = query(
      subscriptionRef,
      where("status", "in", ["trialing", "active"])
    );
    void getDocs(q).then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        setIsValidSubscription(true);
      }
    });
  }, [authData]);

  const goToBillingPortal = async () => {
    const { data } = await createPortalLink({
      returnUrl: window.location.origin,
      //   locale: "auto", // Optional, defaults to "auto"
      //   configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "primary.700", "primary.700"]}
      {...props}
    >
      <Flex align="center">
        <Image boxSize="30px" src="/icon128.png" />
        &nbsp;
        <Heading
          size={"xl"}
          color={["white", "white", "primary.600", "primary.600"]}
          as="a"
          href="/"
        >
          ReadPal.ai
        </Heading>
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
        {show ? <CloseIcon /> : <MenuIcon />}
      </Box>

      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          align="center"
          justify={["space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        >
          <MenuItem to="/#faq">
            <Button
              size="md"
              variant="ghost"
              isLoading={billingClicked}
              color={["white", "white", "primary.600", "primary.600"]}
            >
              FAQ
            </Button>
          </MenuItem>
          {isValidSubscription ? (
            <MenuItem
              onClick={() => {
                setBillingClicked(true);
                goToBillingPortal().then(() => {
                  setBillingClicked(false);
                });
              }}
            >
              <Button
                size="md"
                variant="ghost"
                isLoading={billingClicked}
                color={["white", "white", "primary.600", "primary.600"]}
              >
                Billing
              </Button>
            </MenuItem>
          ) : (
            <MenuItem to="/pricing">
              <Button
                size="md"
                variant="ghost"
                isLoading={billingClicked}
                color={["white", "white", "primary.600", "primary.600"]}
              >
                Pricing
              </Button>
            </MenuItem>
          )}
          {authData.user ? (
            <MenuItem to="/" isLast>
              <Button
                size="md"
                rounded="md"
                color={["primary.600", "primary.600", "white", "white"]}
                bg={["white", "white", "primary.500", "primary.500"]}
                _hover={{
                  bg: [
                    "primary.100",
                    "primary.100",
                    "primary.600",
                    "primary.600",
                  ],
                }}
                onClick={async () => {
                  await auth.signOut();
                  authData.setManuallyLoggedOut(true);
                }}
              >
                Sign out
              </Button>
            </MenuItem>
          ) : (
            <>
              <MenuItem
                to="https://chromewebstore.google.com/detail/readpal/nglglnalnekjnokhnpkecgfnpnmbkoem"
                isLast
              >
                <Button
                  size="md"
                  rounded="md"
                  color={["primary.600", "primary.600", "white", "white"]}
                  bg={["white", "white", "primary.500", "primary.500"]}
                  _hover={{
                    bg: [
                      "primary.100",
                      "primary.100",
                      "primary.600",
                      "primary.600",
                    ],
                  }}
                >
                  Try out for free
                </Button>
              </MenuItem>
            </>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
