/*global chrome*/
import React, { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import {
  User,
  isSignInWithEmailLink,
  signInWithCustomToken,
  signInWithEmailLink,
} from "firebase/auth";
import { auth, getAuthToken } from "../services/Firebase";
import { logData } from "../utils/helpers";

interface Props {
  children: React.ReactNode;
}

export const EXTENSION_ID = "nglglnalnekjnokhnpkecgfnpnmbkoem";

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [manuallyLoggedOut, setManuallyLoggedOut] = useState(false);

  const chromeCheck = () => {
    return typeof chrome !== "undefined" && chrome.runtime;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      if (firebaseUser) {
        getAuthToken({ uid: firebaseUser.uid }).then((result) => {
          // publish to extension
          if (chromeCheck() && result.data) {
            chrome.runtime.sendMessage(EXTENSION_ID, {
              type: "auth_token",
              token: result.data,
            });
          }
        });
      } else if (chromeCheck()) {
        chrome.runtime.sendMessage(EXTENSION_ID, {
          type: "auth_token",
          token: null,
        });
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            logData({
              text: `Sign in with email link failed with: ${error}`,
              severity: "error",
            });
          });
      }
    }
  }, []);

  useEffect(() => {
    if (!user && !manuallyLoggedOut && chromeCheck()) {
      chrome.runtime.sendMessage(
        EXTENSION_ID,
        { type: "get_auth_token" },
        (res) => {
          if (res?.token) {
            signInWithCustomToken(auth, res.token).catch((error) => {});
          }
        }
      );
    }
  }, [user, manuallyLoggedOut]);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        manuallyLoggedOut: manuallyLoggedOut,
        setManuallyLoggedOut: setManuallyLoggedOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
