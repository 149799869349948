import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";
import Header from "../components/Header";
import { Footer } from "../components/Footer";

export default function PageLayout(props: { children: ReactNode }) {
  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ xl: "1200px" }}
      m="0 auto"
      {...props}
    >
      <Header />
      {props.children}
      <Footer />
    </Flex>
  );
}
