import { ReactElement } from "react";
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from "@chakra-ui/react";
import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import {
  LuPictureInPicture2,
  LuLightbulb,
  LuWand2,
  LuBrainCircuit,
} from "react-icons/lu";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box p={50}>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={10}
        color={"primary.800"}
      >
        <Feature
          icon={
            <Icon
              as={LuPictureInPicture2}
              w={10}
              h={10}
              color={"primary.600"}
            />
          }
          title={"Adaptive Companion"}
          text={
            "Whether you're reading news, researching, or shopping, our assistant blends into your workflow, offering support and insights without disrupting your journey."
          }
        />
        <Feature
          icon={
            <Icon as={LuBrainCircuit} w={10} h={10} color={"primary.600"} />
          }
          title={"Smart Assistance"}
          text={
            "Get instant explanations, summaries, recommendations or deeper insights about the content you're viewing. Harness the power of AI to enrich your online activities."
          }
        />
        <Feature
          icon={<Icon as={LuWand2} w={10} h={10} color={"primary.600"} />}
          title={"Streamlined Interaction"}
          text={
            "Designed for seamless integration, it offers keyboard shortcuts, context menus, and one-click prompts, making AI assistance both accessible and unobtrusive."
          }
        />
      </SimpleGrid>
    </Box>
  );
}
