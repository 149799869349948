"use client";

import {
  Box,
  Center,
  Text,
  Stack,
  List,
  ListItem,
  ListIcon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { useState } from "react";

const planFeaturesMap: Record<string, string[]> = {
  Basic: [
    "1,000 queries / month",
    "Support for Google Docs",
    "Rapid customer support",
  ],
  Standard: [
    "3,000 queries / month",
    "100 PDFs / month",
    "Support for Google Docs",
    "Rapid customer support",
  ],
  Pro: [
    "Unlimited queries / month",
    "Unlimited PDFs / month",
    "Support for Google Docs",
    "Rapid customer support",
  ],
};

export default function PricingCard(props: {
  planName: string;
  price: number;
  timeInterval: string;
  buttonTitle: string;
  onClickButton: () => void;
  features: string[];
}) {
  const [isLoading, setIsLoading] = useState(false);
  const getOriginalPrice = (price: number) => {
    if (price === 79) {
      return 99;
    } else if (price === 89) {
      return 199;
    } else {
      return 299;
    }
  };
  return (
    <Center py={6}>
      <Box
        maxW={"330px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.800")}
        boxShadow={"2xl"}
        rounded={"md"}
        overflow={"hidden"}
      >
        <Stack
          textAlign={"center"}
          p={6}
          color={useColorModeValue("gray.800", "white")}
          align={"center"}
        >
          <Text
            fontSize={"sm"}
            fontWeight={500}
            bg={useColorModeValue("green.50", "green.900")}
            p={2}
            px={3}
            color={"green.500"}
            rounded={"full"}
          >
            {props.planName}
          </Text>
          <Stack direction={"row"} align={"center"} justify={"center"}>
            <Text fontSize={"3xl"}>$</Text>
            {props.timeInterval === "year" ? (
              <>
                <Text fontSize={"4xl"} fontWeight={600}>
                  <s>{getOriginalPrice(props.price)}</s>
                </Text>
                <Text fontSize={"4xl"} fontWeight={800} color={"red.400"}>
                  {props.price}
                </Text>
              </>
            ) : (
              <Text fontSize={"6xl"} fontWeight={800}>
                {props.price}
              </Text>
            )}
            <Text color={"gray.500"}>/{props.timeInterval}</Text>
          </Stack>
        </Stack>

        <Box bg={useColorModeValue("gray.50", "gray.900")} px={6} py={10}>
          <List spacing={3} height={200}>
            {planFeaturesMap[props.planName]?.map((feature) => (
              <ListItem>
                <ListIcon as={CheckIcon} color="green.400" />
                {feature}
              </ListItem>
            ))}
          </List>

          <Button
            mt={10}
            w={"full"}
            bg={"green.400"}
            color={"white"}
            rounded={"xl"}
            boxShadow={"0 5px 20px 0px rgb(72 187 120 / 43%)"}
            _hover={{
              bg: "green.500",
            }}
            _focus={{
              bg: "green.500",
            }}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              props.onClickButton();
              setIsLoading(false);
            }}
          >
            {props.buttonTitle}
          </Button>
        </Box>
      </Box>
    </Center>
  );
}
