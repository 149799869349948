/*global chrome*/
import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Landing from "./pages/Landing";
import PageLayout from "./pages/PageLayout";
import Pricing from "./pages/Pricing";
import Login from "./components/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<LayoutWrapper />} />
      </Routes>
    </Router>
  );
}

function LayoutWrapper() {
  const location = useLocation();
  const isSignupPage = location.pathname === "/signup";

  return isSignupPage ? (
    <Routes>
      <Route path="/signup" element={<Login />} />
    </Routes>
  ) : (
    <PageLayout>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </PageLayout>
  );
}

export default App;
