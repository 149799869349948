export function logData(data: {
  text: string;
  uid?: string;
  severity: "info" | "error" | "warning";
  metadata?: any;
}) {
  fetch("https://chatgpt-extension-server-3crwqh4u4q-uc.a.run.app/log", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}
