import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { database } from "../services/Firebase";
import PricingCard from "./PricingCard";
import _, { Dictionary } from "lodash";
import { AuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, VStack, Text, Stack, Icon } from "@chakra-ui/react";
import { ToggleButtonGroup } from "./ButtonToggle";
import { LuAlarmClock } from "react-icons/lu";

export type StripeProduct = {
  planName: string;
  prices: Dictionary<StripePrice>;
  features: string[];
  description: string[];
};

export type StripePrice = {
  priceId: string;
  timeInterval: string;
  price: number;
};

const StripePricingTable = ({ ...props }) => {
  const authData = React.useContext(AuthContext);
  const [products, setProducts] = useState<Array<StripeProduct>>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [interval, setInterval] = useState("year");

  useEffect(() => {
    async function fetchProducts() {
      const productRef = collection(database, "products");
      const q = query(productRef, where("active", "==", true));
      const querySnapshot = await getDocs(q);
      const fetchedProducts = _.compact(
        await Promise.all(
          querySnapshot.docs.map(async (product) => {
            const priceSnap = await getDocs(
              collection(database, "products", product.id, "prices")
            );
            const activePrices = _.groupBy(
              priceSnap.docs.filter((price) => price.data().active),
              (price) => price.data().interval
            );
            if (activePrices["year"] && activePrices["month"]) {
              return {
                planName: product.data().name,
                prices: {
                  month: {
                    priceId: activePrices["month"][0].id,
                    timeInterval: "month",
                    price: activePrices["month"][0].data().unit_amount / 100,
                  },
                  year: {
                    priceId: activePrices["year"][0].id,
                    timeInterval: "year",
                    price: activePrices["year"][0].data().unit_amount / 100,
                  },
                },
                features: product.data().features,
                description: product.data().description,
              };
            }
            return;
          })
        )
      );
      setProducts(
        fetchedProducts.sort(
          (a, b) => a.prices["month"].price - b.prices["month"].price
        )
      );
    }
    if (products.length === 0) {
      fetchProducts();
    }
  }, [products]);

  const onPlanChosen = async (priceId: string) => {
    if (!authData.user) {
      navigate(`/signup?redirectTo=${location.pathname}&signup=true`);
      return;
    }
    const docRef = await addDoc(
      collection(database, "users", props.user.uid, "checkout_sessions"),
      {
        allow_promotion_codes: true,
        line_items: [
          {
            price: priceId,
            quantity: 1,
          },
        ],
        mode: "subscription",
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
    );
    onSnapshot(docRef, (snap) => {
      const data = snap.data();
      if (data) {
        const { error, url } = data;
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          console.log(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
      }
    });
  };

  return (
    <VStack>
      <ToggleButtonGroup
        onChange={(v) => {
          if (v && typeof v[0] === "string") {
            setInterval(v[0]);
          }
        }}
        variant={"outline"}
        exclusive={true}
        colorScheme="primary"
        initialIndex={1}
      >
        <Button value="month">Monthly</Button>
        <Button value="year">Yearly</Button>
      </ToggleButtonGroup>
      <Stack direction={"row"} align={"center"} justify={"center"}>
        <Icon as={LuAlarmClock} color="red.400" boxSize={"6"} />
        <Text color={"red.400"} fontSize={"xl"}>
          10 spots left for new year discount on yearly plan
        </Text>
      </Stack>
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          flexBasis: "auto",
          columnGap: 30,
        }}
      >
        {products.map((product: StripeProduct) => {
          return (
            <div style={{ margin: "auto" }}>
              <PricingCard
                {...product}
                {...product.prices[interval]}
                buttonTitle="Get started"
                onClickButton={async () =>
                  await onPlanChosen(product.prices[interval].priceId)
                }
              />
            </div>
          );
        })}
      </div>
    </VStack>
  );
};

export default StripePricingTable;
