import React, { useState } from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Faq from "../components/Faq";
import SimpleThreeColumns from "../components/Features";
import SimpleAccordion from "../components/Faq";
import WithLargeQuote from "../components/Testimonial";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import Typewriter from "typewriter-effect";
import "./Landing.css";

export default function Landing({ ...props }) {
  const ctaLink =
    "https://chromewebstore.google.com/detail/readpal/nglglnalnekjnokhnpkecgfnpnmbkoem";
  const ctaText = "Try out chrome extension for free";

  const useTitle = () => {
    return (
      // <span>
      //   Supercharge your online{" "}
      //   <span style={{ display: "inline" }}>
      //     <Typewriter
      //       options={{
      //         strings: ["learning", "research", "shopping"],
      //         autoStart: true,
      //         loop: true,
      //       }}
      //     />
      //   </span>
      //   with an AI sidekick
      // </span>
      <span>Supercharge your online activities with an AI sidekick</span>
    );
  };
  return (
    <>
      <Hero
        title={useTitle()}
        subtitle="Online learning, research or shopping - AI assistance side by side on any web pages (including google doc, pdf and more)."
        image="https://source.unsplash.com/collection/404339/800x600"
        ctaText={ctaText}
        ctaLink={ctaLink}
      />
      <WithLargeQuote />
      <SimpleThreeColumns />
      <Link to={ctaLink}>
        <Button
          colorScheme="primary"
          borderRadius="8px"
          py="4"
          px="4"
          lineHeight="1"
          size="md"
          mb="20"
        >
          {ctaText}
        </Button>
      </Link>
      <div id="faq">
        <SimpleAccordion />
      </div>
    </>
  );
}
